<template>
  <v-autocomplete
    v-model="valueMutation"
    v-bind="$attrs"
    outlined
    clearable
    chips
    :items="selectList"
    item-value="id"
    item-text="fullText"
    item-disabled="deleted_at"
    :loading="loadingAction"
    placeholder="Выберите сотрудника"
    v-on="$listeners"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="(typeof data.item !== 'object')">
        <v-list-item-content v-text="data.item" />
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.avatar">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name" />
          <v-list-item-subtitle v-html="data.item.phone" />
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'EmployeeSelect',
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [Number, Object], default: undefined },
    },
    data () {
      return {
        loadingAction: false,
      }
    },
    computed: {
      ...mapGetters({
        items: 'company/staff/staff',
      }),

      selectList () {
        return this.items.map((item) => {
          const fullText = [item.name, item.phone, item.email].join(' ')

          return Object.assign({}, item, {
            phone: item.phone ? '+' + item.phone : '',
            fullText,
          })
        })
      },
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', v)
        },
      },
    },
    watch: {
      accountProgramId (v) {
        this.init()
      },
    },
    created () {
      this.init()
    },

    methods: {
      ...mapActions({
        getItems: 'company/staff/list',
      }),
      remove (item) {
        this.valueMutation = null
        // const index = this.valueMutation.indexOf(item.id)
        // if (index >= 0) this.valueMutation.splice(index, 1)
      },
      async init () {
        try {
          this.loadingAction = true
          await this.getItems()
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
